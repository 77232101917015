<template>
    <form @submit.prevent="submit()">
        <b-form-group :label="$t('crud.users.username')">
            <b-form-input v-model="record.username" required :disabled="!!record.id"></b-form-input>
        </b-form-group>
        <b-form-group :label="$t('crud.users.email')">
            <b-form-input v-model="record.email" type="email" required></b-form-input>
        </b-form-group>
        <b-form-group :label="$t('crud.users.first_name')">
            <b-form-input v-model="record.first_name"></b-form-input>
        </b-form-group>
        <b-form-group :label="$t('crud.users.last_name')">
            <b-form-input v-model="record.last_name"></b-form-input>
        </b-form-group>
        <b-form-group :label="$t('crud.users.company')">
            <b-form-input v-model="record.company"></b-form-input>
        </b-form-group>
        <div class="row">
            <div class="col">
                <b-form-group :label="$t('crud.users.reset_password')">
                    <b-form-input v-model="record.password" type="password"></b-form-input>
                </b-form-group>
            </div>
            <div class="col">
                <b-form-group :label="$t('crud.users.reset_password_confirmation')">
                    <b-form-input v-model="record.password_confirmation" type="password"></b-form-input>
                </b-form-group>
            </div>
        </div>
      <b-form-group :label="$t('crud.users.role')" v-if="this.$auth.user().is_admin == 1">
        <b-form-select v-model="record.is_admin" required>
          <option :value="0">{{ $t('crud.users.roles.seo') }}</option>
          <option :value="1">{{ $t('crud.users.roles.admin') }}</option>
          <option :value="2">{{ $t('crud.users.roles.administration') }}</option>
          <option :value="3">{{ $t('crud.users.roles.call_center') }}</option>
        </b-form-select>
      </b-form-group>
        <button class="btn btn-primary" type="submit">{{ $t('crud.save') }}</button>
    </form>
</template>

<script>
    export default {
        name: 'users-form-component',
        props: ['record'],
        methods: {
            submit() {
                this.$emit('submit');
            }
        }
    }
</script>

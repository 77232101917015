<template>
    <loading-card :loading="!loaded">
        <div class="card-header">{{ $t('crud.users._show') }}</div>
        <div class="card-body">
            <users-form-component @submit="updateFromRoute" :record="record"></users-form-component>
        </div>
    </loading-card>
</template>

<script>
    import {functions, config} from '../crud/mixin_config';
    import UsersFormComponent from "./Form";

    export default {
        name: 'users-show-component',
        mixins: [functions],
        components: {UsersFormComponent},
        data() {
            return {
                crud: config('users')
            };
        }
    }
</script>
